.edit {
  place-items: center;
}

input, textarea, label {
  font-size: 1rem;
  padding: .5rem;
  display: block;
  margin-bottom: 3rem;
}


.checkboxes {
  display: grid;
  grid-template-columns: 1fr 4fr;
}

textarea {
  min-width: 50vw;
  min-height: 20vh;
}

.project-images {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-bottom: 3rem;
}

.project-images > div {
  position: relative;
  display: grid;

}

.project-images img {
  max-height: 250px;
  width: 100%;
  object-fit: cover;
  gap: 1rem;
}

.imageNameIndicator {
  text-transform: uppercase;
  text-align: center;
  font-size: 1rem;
  padding: 0 2rem;
}

select {
  margin-bottom: 3rem;
}

.edit img {
  width: 20rem;
  padding: 2rem;
}

.edit label {
  padding: 0;
}

.uploadButton {
  width: 120px;
  height: 30px;
  font-family: 'Josefin sans';
  text-transform: uppercase;
  padding: 10px;
  cursor: pointer;
  display: grid;
  place-items: center;
  font-size: .6rem;
  border: 1px solid #fff;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, .2) 1px 1px 2px;
  margin-top: 1rem;
}