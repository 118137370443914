@import url(https://use.typekit.net/tsd0bbh.css);
@import url(https://fonts.googleapis.com/css?family=Josefin+Sans:100,100i,300,300i,400,400i,600,600i,700,700i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Eczar&display=swap);
@import url(https://fonts.googleapis.com/css?family=Yeseva+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Default settings */

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background-color: #161616;
  color: #fff;
}

main {
  min-height: 100vh;
  display: grid;
  width: 100vw;
  padding: 10vh 0;
 }

h1 {
  font-family: "Eczar", serif;
  display: grid;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 100;
  color: #fff;
  margin-bottom: 1rem;
}

p {
  font-family: "Josefin sans", sans-serif;
}

a {
  color: #fff;
  text-decoration: none;
}

button {
  width: 150px;
  height: 50px;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 2px;
  font-family: "Josefin sans";
  text-transform: uppercase;
  padding: 20px;
  cursor: pointer;
  display: grid;
  place-items: center;
  font-size: 0.6rem;
  background-color: #161616;
  border: 1px solid #fff;
  color: #fff;
}

.icons {
  -webkit-transform: scale(2);
          transform: scale(2);
  cursor: pointer;
}

.project-container{
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
  height: 100vh;
  overflow: scroll;
}

.projects-grid {
  display: grid;
  grid-gap: 5rem;
  gap: 5rem;
  width: 100%;
  padding: 0 2rem;
}

.add {
  position: fixed;
  width: 60px;
  height: 60px;
  right: 3vw;
  top: 80vh;
  border-radius: 50%;
  background: #161616;
  border: 2px solid #fff;
  display: grid;
  place-items: center;
  box-shadow: rgba(0, 0, 0, .4) 1px 3px 10px;
  z-index: 5;
}

.admin-icons {
  position: absolute;
  right: 2rem;
  bottom: 1rem;
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  grid-gap: 1rem;
  gap: 1rem;
  cursor: pointer;
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  color: #fff;
}

.project {
  display: grid;
  height: 100vh;
  position: relative;
  color: #161616;
  padding: 10rem 10rem;
  scroll-snap-align: center;
  scroll-snap-stop: normal;
}

.project .byline{
  margin-bottom: 1rem;
}

.project .project-overview-img {
  width: 80%;
  height: auto;
  object-fit: cover;
  align-self: center;
  justify-self: end;
  box-shadow:  0px 10px 30px -5px rgba(0, 0, 0, .3);
  cursor: pointer;
}

.project .display-title {
  font-family: 'Yeseva One', cursive;
  position: absolute;
  font-size: 5rem;
  color: #fff;
  top: 25vh;
  left: 10vw;
  width: 20%;
}

.project .view-project {
  position: absolute;
  top: 85vh;
  right: 10vw;
  opacity: 0.5;
  -webkit-transition: .3s ease;
  transition: .3s ease;
}

.project .view-project:hover {
  opacity: 1;
  -webkit-transform: translateX(2px);
          transform: translateX(2px);
}

.project .admin-icons {
  position: absolute;
  right: 5vw;
  bottom: 5vh;
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  grid-gap: 1rem;
  gap: 1rem;
  cursor: pointer;
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  color: #fff;
}

@media (max-width: 900px) {
  .project {
    /* grid-template-rows: repeat(3, 1fr);
    grid-column: repeat(2, 1fr); */
    height: auto;
    position: relative;
    max-width: 100vh;
    padding: 0;
    padding-bottom: 7rem;
    scroll-snap-align: none;
    scroll-snap-stop: none;
  } 

  .project:nth-child(1) {
    padding: 20vh 0 7rem 0;
  }

  .project .project-overview-img {
    grid-column: auto;
    grid-row: auto;
    width: 70%;
    justify-self: center;
    padding: 0;
    margin: 0;
    opacity: 0.5;
  }

  .project .display-title {
    grid-row: 1 / 2;
    grid-column: 1 / 3;
    font-size: 2rem;
    /* padding: 0;
    margin: 0; */
    top: -1rem;
    left: 3rem;
    position: absolute;
    width: 50%;
  }

  .project .view-project {
    display: none;
  }
}

.introPage {
  display: grid;
  place-items: start;
  align-content: start;
  padding-left: 10rem;
}

.introPage .name-wrapper {
  display: grid;
  margin-top: 10rem;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 2rem;
  gap: 2rem;
}

.introPage .name-wrapper .name, .separator, .nav-projects {
  color: #fff;
  font-size: 4rem;
  justify-content: start;
  font-family: 'Yeseva One', cursive;
  font-family: "Eczar", serif;
  -webkit-transition: .1s;
  transition: .1s;
}

.introPage .name-wrapper .nav-projects:hover {
  -webkit-transform: translateX(.5rem);
          transform: translateX(.5rem);
  cursor: pointer;
}

.introPage p{
  color: #fff;
  font-size: 2rem;
  justify-content: start;
  font-family: "Eczar", serif;
  padding-top: 1rem;
}

@media (max-width: 900px) {

  .introPage {
    padding-left: 2rem;
    overflow: hidden;
  }

  .introPage .name-wrapper {
    display: grid;
    margin-top: 5rem;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    gap: 2rem;
  }

  .introPage .name-wrapper .name{
    font-size: 3rem;
  }
  .introPage .name-wrapper .separator, .nav-projects {
    font-size: 2rem;
    display: none;
  }

  .introPage p {
    font-size: 1.5rem;
  }
}

.about {
  display: grid;
  grid-auto-flow: row;
  padding-top: 10rem;
}

.about .info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 30vh;
}

/* .about .contact-info {
  display: grid;
  grid-auto-flow: column;
  font-size: 1.5rem;
  gap: 2rem;
  font-family: "Eczar", serif;
} */

.about .contact-title {
  font-size: 4rem;
  font-family: 'eskapade-fraktur', serif;
  font-family: 'Yeseva One', cursive;
  margin-bottom: 5rem;
  -webkit-animation: fadeIn 1.5s ease .5s;
          animation: fadeIn 1.5s ease .5s;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
}

.about .description {
  width: 100%;
  padding-right: 2rem;
  font-family: "Eczar", serif;
  font-size: 1.3rem;
  -webkit-animation: fadeIn 1.5s ease .75s;
          animation: fadeIn 1.5s ease .75s;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
}

.about .contact-list-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: end;
  grid-gap: 1rem;
  gap: 1rem;
  border-left: 1px solid #fff;
  padding-left: 3rem;
  -webkit-animation: fadeIn 1.5s ease .75s;
          animation: fadeIn 1.5s ease .75s;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
}

.about .contact-list-info .list-info-items {
 justify-content: end;
}

.about .contact-list-info .list-info-items .tagName {
  font-family: 'Yeseva One', cursive;
  font-size: 1rem;
  font-weight: 300;
}

@media (max-width: 1200px) {

  .about {
    justify-content: center;
    padding-top: 5rem;
    padding-left: 2rem;
    overflow-x: hidden;
  }

  .about section {
    margin-bottom: 3rem;
  }

  .about .top-info-section {
    padding: 0 2rem;
  }

  .about .description {
    font-size: 1.1rem;
    margin-bottom: 3rem;
    width: 90vw;
  }

  .about .contact-title {
    font-size: 3rem;
    padding-left: 1rem;
    margin-top: 7rem;
    margin-bottom: 3rem;
  }

  .about .contact-list-info {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-auto-flow: row;
    justify-content: start;
    margin-bottom: 2rem;
  }
  
  /* .about .contact-list-info .list-info-items {
    display: grid;
    grid-auto-flow: row;;
   justify-content: start;
  } */

  .about .top-info-section .info-grid {
    display: block;
    padding-left: 1rem;
    padding-right: 2rem;
    margin-bottom: 3rem;
  }

}
.header-container {
  position: fixed;
  width: 100%;
  height: 10vh;
  display: grid;
  place-items: end;
  z-index: 2;
}

.header-container header a {
  font-size: 1.3rem;
  text-decoration: none;
  color: #fff;
  font-family: "Eczar", serif;
  justify-items: end;

}

.logo-home {
  width: 2.5rem;
  margin-left: 2rem;
  margin-top: 1rem;
}

.header-container header p {
  font-size: 2.5rem;
  font-weight: 100;
  color: #fff;
  font-family: "Eskapade Fraktur", serif;
  justify-content: start;
  margin-left: 2rem;
  margin-right: 2rem;

}

.burger-menu {
  display: none;
  z-index: 2;
}

header {
  width: 100vw;
  height: 10vh;
  display: grid;
  grid-template-columns:  1fr 1fr;
  justify-content: start;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  z-index: 1;
  z-index: 3;
}

.header-right-items {
  display: grid;
  grid-auto-flow: column;
  place-content: end;
  grid-gap: 1rem;
  gap: 1rem;
  margin-right: 2rem;
  align-items: center;
}

.header-right-items .divider {
  margin: 0;
}

[aria-current='page'], .active {
  font-weight: bold;
}

@media (max-width: 900px) {
  .header-container header p {
    font-size: 1.5rem;
    font-family: "Eskapade Fraktur", serif;
  }

  .logo-home {
    width: 2rem;
  }

}
.edit {
  place-items: center;
}

input, textarea, label {
  font-size: 1rem;
  padding: .5rem;
  display: block;
  margin-bottom: 3rem;
}


.checkboxes {
  display: grid;
  grid-template-columns: 1fr 4fr;
}

textarea {
  min-width: 50vw;
  min-height: 20vh;
}

.project-images {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 3rem;
}

.project-images > div {
  position: relative;
  display: grid;

}

.project-images img {
  max-height: 250px;
  width: 100%;
  object-fit: cover;
  grid-gap: 1rem;
  gap: 1rem;
}

.imageNameIndicator {
  text-transform: uppercase;
  text-align: center;
  font-size: 1rem;
  padding: 0 2rem;
}

select {
  margin-bottom: 3rem;
}

.edit img {
  width: 20rem;
  padding: 2rem;
}

.edit label {
  padding: 0;
}

.uploadButton {
  width: 120px;
  height: 30px;
  font-family: 'Josefin sans';
  text-transform: uppercase;
  padding: 10px;
  cursor: pointer;
  display: grid;
  place-items: center;
  font-size: .6rem;
  border: 1px solid #fff;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, .2) 1px 1px 2px;
  margin-top: 1rem;
}
.project-details {
  display: grid;
  grid-auto-flow: row;
  padding-top: 10rem;
}

section {
  display: grid;
  margin-bottom: 5rem;
}

.top-info-section {
  padding: 0 15rem;
}

.project-details .project-details-content {
 display: grid;
 grid-gap: 2rem;
 gap: 2rem;
}

.project-details .project-details-content .edit-button {
  position: absolute;
  font-size: 1.5rem;
  top: 25vh;
  left: 10vw;
  -webkit-transition: .3s ease;
  transition: .3s ease;
  opacity: 0.5;
}

.project-details .project-details-content .back-button {
  position: absolute;
  font-size: 1.5rem;
  top: 15vh;
  left: 10vw;
  -webkit-transition: .3s ease;
  transition: .3s ease;
  opacity: 0.5;
}

.project-details .project-details-content .back-button:hover {
  position: absolute;
  top: 15vh;
  left: 10vw;
  opacity: 1;
  -webkit-transform: translateX(-2px);
          transform: translateX(-2px);
}

.project-details .project-details-content .info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.project-details .project-details-content .project-detail-title {
  font-size: 4rem;
  font-family: 'eskapade-fraktur', serif;
  font-family: 'Yeseva One', cursive;
  margin-bottom: 7rem;
  -webkit-animation: fadeIn 1.5s ease .5s;
          animation: fadeIn 1.5s ease .5s;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
}

.project-details-content .description {
  width: 100%;
  padding-right: 2rem;
  font-family: "Eczar", serif;
  font-size: 1.3rem;
  -webkit-animation: fadeIn 1.5s ease .75s;
          animation: fadeIn 1.5s ease .75s;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
}

.project-details-content .project-list-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: end;
  grid-gap: 1rem;
  gap: 1rem;
  border-left: 1px solid #fff;
  padding-left: 3rem;
  -webkit-animation: fadeIn 1.5s ease .75s;
          animation: fadeIn 1.5s ease .75s;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
}

.project-details-content .project-list-info .list-info-items {
 justify-content: end;
}

.project-details-content .project-list-info .list-info-items .tagName {
  font-family: 'Yeseva One', cursive;
  font-size: 1rem;
  font-weight: 300;
}

.project-details .project-details-img-intro {
  box-shadow:  none;
  width: 70%;
  justify-self: center;
  -webkit-animation: fadeIn 1.5s ease 1s;
          animation: fadeIn 1.5s ease 1s;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
}

.project-details .project-details-img-result {
  box-shadow:  none;
  width: 100%;
  -webkit-animation: fadeIn 1.5s ease 1s;
          animation: fadeIn 1.5s ease 1s;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
}

.project-details .project-details-content .concept-section, .target-audience-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 15rem;
  grid-gap: 1rem;
  gap: 1rem;
}


.project-details .project-details-content .concept-section .concept-description .concept-title {
  font-size: 4rem;
  font-family: 'Yeseva One', cursive;
  text-align: left;
  margin-bottom: 3rem;
}

.project-details .project-details-content .concept-section .concept-name .concept-title {
  font-size: 3rem;
  font-family: 'Eczar', serif;
  text-align: left;
}

.project-details .project-details-content .concept-section .concept-description, .concept-name {
  font-size: 1.3rem;
  margin-top: 10rem;
  margin-bottom: 7rem;
  font-family: "Eczar", serif;
  text-align: left;
  justify-self: left;
  padding-right: 5rem;
  grid-column: 1 / 2;
}

.project-details .project-details-content .concept-section .concept-name {
  grid-column: 2 / 3;
  border-left: 1px solid #fff;
  padding-left: 3rem;
}

.pager {
  background: #161616;
  text-decoration: none;
  width: 100vw;
  height: 10vh;
  margin-top: 10vh;
  display: grid;
  grid-auto-flow: column;
  z-index: 5;
}

.pager .navigate-projects {
  display: grid;
  grid-auto-flow: column;
  place-content: end;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 4rem;
}

.navigate-projects .prev {
  margin-left: 4rem;
  font-size: .9rem;
  justify-self: end;
  opacity: 0.5;
  -webkit-transition: .3s ease;
  transition: .3s ease;
}

.navigate-projects .divider {
  opacity: 0.5;
}

.navigate-projects .next {
  margin-right: 4rem;
  font-size: .9rem;
  justify-self: end;
  opacity: 0.5;
  -webkit-transition: .3s ease;
  transition: .3s ease;
}

.navigate-projects .prev:hover {
  opacity: 1;
  -webkit-transform: translateX(-2px);
          transform: translateX(-2px);
}

.navigate-projects .next:hover {
  opacity: 1;
  -webkit-transform: translateX(2px);
          transform: translateX(2px);
}

@media (max-width: 900px) {
  .project-details {
    justify-content: center;
    padding: 0;
  }

  section {
    margin-bottom: 3rem;
  }

  .top-info-section {
    padding: 0;
  }
  .project-details-content .description {
    margin-bottom: 3rem;
  }

  .project-details .project-details-content .project-detail-title {
    font-size: 3rem;
    padding-left: 2rem;
    margin-top: 7rem;
    margin-bottom: 3rem;
  }

  .project-details .project-details-content .info-grid {
    display: block;
    padding-left: 2rem;
    padding-right: 1rem;
    margin-bottom: 3rem;
  }

  .project-details .project-details-img-intro {
    width: 100%;
  }

  .project-details .project-details-content .concept-section {
    padding: 0;
  }

  .project-details .project-details-content .concept-section .concept-description {
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin-top: 2rem;
    font-family: "Eczar", serif;
    padding: 2rem;
    text-align: left;
    grid-column: 1 / 3;
  }
  .project-details-content .description {
    margin-bottom: 3rem;
  }

  .project-details .project-details-content .project-detail-title {
    font-size: 3rem;
    padding-left: 2rem;
    margin-top: 7rem;
    margin-bottom: 3rem;
  }

  .project-details .project-details-content .info-grid {
    display: block;
    padding-left: 2rem;
    padding-right: 1rem;
    margin-bottom: 3rem;
  }

  .project-details .project-details-img-intro {
    width: 100%;
  }

  .project-details .project-details-content .concept-section {
    padding: 0;
  }

  .project-details .project-details-content .concept-section .concept-description {
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin-top: 2rem;
    font-family: "Eczar", serif;
    padding: 2rem;
    text-align: left;
    grid-column: 1 / 3;
  }
  
  .project-details .project-details-content .concept-section .concept-description, .concept-name {
    padding-right: 2rem;
    margin-bottom: 1rem;
    grid-column: 1 / 3;
  }

  .project-details .project-details-content .concept-section .concept-name {
    grid-column: 1 / 3;
    border-left: 1px solid #fff;
    padding-left: 2rem;
    margin-left: 2rem;
    margin-top: 0;
  }

  .pager {
    justify-content: center;
  }

  .pager .navigate-projects {
    display: grid;
    grid-auto-flow: column;
    place-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 4rem;
  }
  
  .navigate-projects .prev {
    margin-left: 4rem;
    font-size: .9rem;
    justify-self: center;
    opacity: 0.5;
    -webkit-transition: .3s ease;
    transition: .3s ease;
  }
  
  .navigate-projects .divider {
    opacity: 0.5;
  }
  
  .navigate-projects .next {
    margin-right: 4rem;
    font-size: .9rem;
    justify-self: center;
    opacity: 0.5;
    -webkit-transition: .3s ease;
    transition: .3s ease;
  }
} 
