.about {
  display: grid;
  grid-auto-flow: row;
  padding-top: 10rem;
}

.about .info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 30vh;
}

/* .about .contact-info {
  display: grid;
  grid-auto-flow: column;
  font-size: 1.5rem;
  gap: 2rem;
  font-family: "Eczar", serif;
} */

.about .contact-title {
  font-size: 4rem;
  font-family: 'eskapade-fraktur', serif;
  font-family: 'Yeseva One', cursive;
  margin-bottom: 5rem;
  animation: fadeIn 1.5s ease .5s;
  animation-fill-mode: backwards;
}

.about .description {
  width: 100%;
  padding-right: 2rem;
  font-family: "Eczar", serif;
  font-size: 1.3rem;
  animation: fadeIn 1.5s ease .75s;
  animation-fill-mode: backwards;
}

.about .contact-list-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: end;
  gap: 1rem;
  border-left: 1px solid #fff;
  padding-left: 3rem;
  animation: fadeIn 1.5s ease .75s;
  animation-fill-mode: backwards;
}

.about .contact-list-info .list-info-items {
 justify-content: end;
}

.about .contact-list-info .list-info-items .tagName {
  font-family: 'Yeseva One', cursive;
  font-size: 1rem;
  font-weight: 300;
}

@media (max-width: 1200px) {

  .about {
    justify-content: center;
    padding-top: 5rem;
    padding-left: 2rem;
    overflow-x: hidden;
  }

  .about section {
    margin-bottom: 3rem;
  }

  .about .top-info-section {
    padding: 0 2rem;
  }

  .about .description {
    font-size: 1.1rem;
    margin-bottom: 3rem;
    width: 90vw;
  }

  .about .contact-title {
    font-size: 3rem;
    padding-left: 1rem;
    margin-top: 7rem;
    margin-bottom: 3rem;
  }

  .about .contact-list-info {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-auto-flow: row;
    justify-content: start;
    margin-bottom: 2rem;
  }
  
  /* .about .contact-list-info .list-info-items {
    display: grid;
    grid-auto-flow: row;;
   justify-content: start;
  } */

  .about .top-info-section .info-grid {
    display: block;
    padding-left: 1rem;
    padding-right: 2rem;
    margin-bottom: 3rem;
  }

}