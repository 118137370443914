@import url("https://use.typekit.net/tsd0bbh.css");
@import url("https://fonts.googleapis.com/css?family=Josefin+Sans:100,100i,300,300i,400,400i,600,600i,700,700i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Eczar&display=swap");
@import url('https://fonts.googleapis.com/css?family=Yeseva+One&display=swap');

/* Default settings */

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background-color: #161616;
  color: #fff;
}

main {
  min-height: 100vh;
  display: grid;
  width: 100vw;
  padding: 10vh 0;
 }

h1 {
  font-family: "Eczar", serif;
  display: grid;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 100;
  color: #fff;
  margin-bottom: 1rem;
}

p {
  font-family: "Josefin sans", sans-serif;
}

a {
  color: #fff;
  text-decoration: none;
}

button {
  width: 150px;
  height: 50px;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 2px;
  font-family: "Josefin sans";
  text-transform: uppercase;
  padding: 20px;
  cursor: pointer;
  display: grid;
  place-items: center;
  font-size: 0.6rem;
  background-color: #161616;
  border: 1px solid #fff;
  color: #fff;
}

.icons {
  transform: scale(2);
  cursor: pointer;
}
