.header-container {
  position: fixed;
  width: 100%;
  height: 10vh;
  display: grid;
  place-items: end;
  z-index: 2;
}

.header-container header a {
  font-size: 1.3rem;
  text-decoration: none;
  color: #fff;
  font-family: "Eczar", serif;
  justify-items: end;

}

.logo-home {
  width: 2.5rem;
  margin-left: 2rem;
  margin-top: 1rem;
}

.header-container header p {
  font-size: 2.5rem;
  font-weight: 100;
  color: #fff;
  font-family: "Eskapade Fraktur", serif;
  justify-content: start;
  margin-left: 2rem;
  margin-right: 2rem;

}

.burger-menu {
  display: none;
  z-index: 2;
}

header {
  width: 100vw;
  height: 10vh;
  display: grid;
  grid-template-columns:  1fr 1fr;
  justify-content: start;
  align-items: center;
  gap: 1rem;
  z-index: 1;
  z-index: 3;
}

.header-right-items {
  display: grid;
  grid-auto-flow: column;
  place-content: end;
  gap: 1rem;
  margin-right: 2rem;
  align-items: center;
}

.header-right-items .divider {
  margin: 0;
}

[aria-current='page'], .active {
  font-weight: bold;
}

@media (max-width: 900px) {
  .header-container header p {
    font-size: 1.5rem;
    font-family: "Eskapade Fraktur", serif;
  }

  .logo-home {
    width: 2rem;
  }

}