
.introPage {
  display: grid;
  place-items: start;
  align-content: start;
  padding-left: 10rem;
}

.introPage .name-wrapper {
  display: grid;
  margin-top: 10rem;
  grid-template-columns: 1fr auto 1fr;
  gap: 2rem;
}

.introPage .name-wrapper .name, .separator, .nav-projects {
  color: #fff;
  font-size: 4rem;
  justify-content: start;
  font-family: 'Yeseva One', cursive;
  font-family: "Eczar", serif;
  transition: .1s;
}

.introPage .name-wrapper .nav-projects:hover {
  transform: translateX(.5rem);
  cursor: pointer;
}

.introPage p{
  color: #fff;
  font-size: 2rem;
  justify-content: start;
  font-family: "Eczar", serif;
  padding-top: 1rem;
}

@media (max-width: 900px) {

  .introPage {
    padding-left: 2rem;
    overflow: hidden;
  }

  .introPage .name-wrapper {
    display: grid;
    margin-top: 5rem;
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .introPage .name-wrapper .name{
    font-size: 3rem;
  }
  .introPage .name-wrapper .separator, .nav-projects {
    font-size: 2rem;
    display: none;
  }

  .introPage p {
    font-size: 1.5rem;
  }
}
