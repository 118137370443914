.project-container{
  scroll-snap-type: y mandatory;
  height: 100vh;
  overflow: scroll;
}

.projects-grid {
  display: grid;
  gap: 5rem;
  width: 100%;
  padding: 0 2rem;
}

.add {
  position: fixed;
  width: 60px;
  height: 60px;
  right: 3vw;
  top: 80vh;
  border-radius: 50%;
  background: #161616;
  border: 2px solid #fff;
  display: grid;
  place-items: center;
  box-shadow: rgba(0, 0, 0, .4) 1px 3px 10px;
  z-index: 5;
}

.admin-icons {
  position: absolute;
  right: 2rem;
  bottom: 1rem;
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  gap: 1rem;
  cursor: pointer;
  transform: scale(1.3);
  color: #fff;
}