
.project {
  display: grid;
  height: 100vh;
  position: relative;
  color: #161616;
  padding: 10rem 10rem;
  scroll-snap-align: center;
  scroll-snap-stop: normal;
}

.project .byline{
  margin-bottom: 1rem;
}

.project .project-overview-img {
  width: 80%;
  height: auto;
  object-fit: cover;
  align-self: center;
  justify-self: end;
  box-shadow:  0px 10px 30px -5px rgba(0, 0, 0, .3);
  cursor: pointer;
}

.project .display-title {
  font-family: 'Yeseva One', cursive;
  position: absolute;
  font-size: 5rem;
  color: #fff;
  top: 25vh;
  left: 10vw;
  width: 20%;
}

.project .view-project {
  position: absolute;
  top: 85vh;
  right: 10vw;
  opacity: 0.5;
  transition: .3s ease;
}

.project .view-project:hover {
  opacity: 1;
  transform: translateX(2px);
}

.project .admin-icons {
  position: absolute;
  right: 5vw;
  bottom: 5vh;
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  gap: 1rem;
  cursor: pointer;
  transform: scale(1.3);
  color: #fff;
}

@media (max-width: 900px) {
  .project {
    /* grid-template-rows: repeat(3, 1fr);
    grid-column: repeat(2, 1fr); */
    height: auto;
    position: relative;
    max-width: 100vh;
    padding: 0;
    padding-bottom: 7rem;
    scroll-snap-align: none;
    scroll-snap-stop: none;
  } 

  .project:nth-child(1) {
    padding: 20vh 0 7rem 0;
  }

  .project .project-overview-img {
    grid-column: auto;
    grid-row: auto;
    width: 70%;
    justify-self: center;
    padding: 0;
    margin: 0;
    opacity: 0.5;
  }

  .project .display-title {
    grid-row: 1 / 2;
    grid-column: 1 / 3;
    font-size: 2rem;
    /* padding: 0;
    margin: 0; */
    top: -1rem;
    left: 3rem;
    position: absolute;
    width: 50%;
  }

  .project .view-project {
    display: none;
  }
}