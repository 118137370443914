.project-details {
  display: grid;
  grid-auto-flow: row;
  padding-top: 10rem;
}

section {
  display: grid;
  margin-bottom: 5rem;
}

.top-info-section {
  padding: 0 15rem;
}

.project-details .project-details-content {
 display: grid;
 gap: 2rem;
}

.project-details .project-details-content .edit-button {
  position: absolute;
  font-size: 1.5rem;
  top: 25vh;
  left: 10vw;
  transition: .3s ease;
  opacity: 0.5;
}

.project-details .project-details-content .back-button {
  position: absolute;
  font-size: 1.5rem;
  top: 15vh;
  left: 10vw;
  transition: .3s ease;
  opacity: 0.5;
}

.project-details .project-details-content .back-button:hover {
  position: absolute;
  top: 15vh;
  left: 10vw;
  opacity: 1;
  transform: translateX(-2px);
}

.project-details .project-details-content .info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.project-details .project-details-content .project-detail-title {
  font-size: 4rem;
  font-family: 'eskapade-fraktur', serif;
  font-family: 'Yeseva One', cursive;
  margin-bottom: 7rem;
  animation: fadeIn 1.5s ease .5s;
  animation-fill-mode: backwards;
}

.project-details-content .description {
  width: 100%;
  padding-right: 2rem;
  font-family: "Eczar", serif;
  font-size: 1.3rem;
  animation: fadeIn 1.5s ease .75s;
  animation-fill-mode: backwards;
}

.project-details-content .project-list-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: end;
  gap: 1rem;
  border-left: 1px solid #fff;
  padding-left: 3rem;
  animation: fadeIn 1.5s ease .75s;
  animation-fill-mode: backwards;
}

.project-details-content .project-list-info .list-info-items {
 justify-content: end;
}

.project-details-content .project-list-info .list-info-items .tagName {
  font-family: 'Yeseva One', cursive;
  font-size: 1rem;
  font-weight: 300;
}

.project-details .project-details-img-intro {
  box-shadow:  none;
  width: 70%;
  justify-self: center;
  animation: fadeIn 1.5s ease 1s;
  animation-fill-mode: backwards;
}

.project-details .project-details-img-result {
  box-shadow:  none;
  width: 100%;
  animation: fadeIn 1.5s ease 1s;
  animation-fill-mode: backwards;
}

.project-details .project-details-content .concept-section, .target-audience-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 15rem;
  gap: 1rem;
}


.project-details .project-details-content .concept-section .concept-description .concept-title {
  font-size: 4rem;
  font-family: 'Yeseva One', cursive;
  text-align: left;
  margin-bottom: 3rem;
}

.project-details .project-details-content .concept-section .concept-name .concept-title {
  font-size: 3rem;
  font-family: 'Eczar', serif;
  text-align: left;
}

.project-details .project-details-content .concept-section .concept-description, .concept-name {
  font-size: 1.3rem;
  margin-top: 10rem;
  margin-bottom: 7rem;
  font-family: "Eczar", serif;
  text-align: left;
  justify-self: left;
  padding-right: 5rem;
  grid-column: 1 / 2;
}

.project-details .project-details-content .concept-section .concept-name {
  grid-column: 2 / 3;
  border-left: 1px solid #fff;
  padding-left: 3rem;
}

.pager {
  background: #161616;
  text-decoration: none;
  width: 100vw;
  height: 10vh;
  margin-top: 10vh;
  display: grid;
  grid-auto-flow: column;
  z-index: 5;
}

.pager .navigate-projects {
  display: grid;
  grid-auto-flow: column;
  place-content: end;
  gap: 1rem;
  margin-bottom: 4rem;
}

.navigate-projects .prev {
  margin-left: 4rem;
  font-size: .9rem;
  justify-self: end;
  opacity: 0.5;
  transition: .3s ease;
}

.navigate-projects .divider {
  opacity: 0.5;
}

.navigate-projects .next {
  margin-right: 4rem;
  font-size: .9rem;
  justify-self: end;
  opacity: 0.5;
  transition: .3s ease;
}

.navigate-projects .prev:hover {
  opacity: 1;
  transform: translateX(-2px);
}

.navigate-projects .next:hover {
  opacity: 1;
  transform: translateX(2px);
}

@media (max-width: 900px) {
  .project-details {
    justify-content: center;
    padding: 0;
  }

  section {
    margin-bottom: 3rem;
  }

  .top-info-section {
    padding: 0;
  }
  .project-details-content .description {
    margin-bottom: 3rem;
  }

  .project-details .project-details-content .project-detail-title {
    font-size: 3rem;
    padding-left: 2rem;
    margin-top: 7rem;
    margin-bottom: 3rem;
  }

  .project-details .project-details-content .info-grid {
    display: block;
    padding-left: 2rem;
    padding-right: 1rem;
    margin-bottom: 3rem;
  }

  .project-details .project-details-img-intro {
    width: 100%;
  }

  .project-details .project-details-content .concept-section {
    padding: 0;
  }

  .project-details .project-details-content .concept-section .concept-description {
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin-top: 2rem;
    font-family: "Eczar", serif;
    padding: 2rem;
    text-align: left;
    grid-column: 1 / 3;
  }
  .project-details-content .description {
    margin-bottom: 3rem;
  }

  .project-details .project-details-content .project-detail-title {
    font-size: 3rem;
    padding-left: 2rem;
    margin-top: 7rem;
    margin-bottom: 3rem;
  }

  .project-details .project-details-content .info-grid {
    display: block;
    padding-left: 2rem;
    padding-right: 1rem;
    margin-bottom: 3rem;
  }

  .project-details .project-details-img-intro {
    width: 100%;
  }

  .project-details .project-details-content .concept-section {
    padding: 0;
  }

  .project-details .project-details-content .concept-section .concept-description {
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin-top: 2rem;
    font-family: "Eczar", serif;
    padding: 2rem;
    text-align: left;
    grid-column: 1 / 3;
  }
  
  .project-details .project-details-content .concept-section .concept-description, .concept-name {
    padding-right: 2rem;
    margin-bottom: 1rem;
    grid-column: 1 / 3;
  }

  .project-details .project-details-content .concept-section .concept-name {
    grid-column: 1 / 3;
    border-left: 1px solid #fff;
    padding-left: 2rem;
    margin-left: 2rem;
    margin-top: 0;
  }

  .pager {
    justify-content: center;
  }

  .pager .navigate-projects {
    display: grid;
    grid-auto-flow: column;
    place-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
  }
  
  .navigate-projects .prev {
    margin-left: 4rem;
    font-size: .9rem;
    justify-self: center;
    opacity: 0.5;
    transition: .3s ease;
  }
  
  .navigate-projects .divider {
    opacity: 0.5;
  }
  
  .navigate-projects .next {
    margin-right: 4rem;
    font-size: .9rem;
    justify-self: center;
    opacity: 0.5;
    transition: .3s ease;
  }
} 